<template>
  <div>
    <!--    筛选条件-->
    <bonus-search :isType="2" @doSearch="doSearch"/>
    <div class="default-table">
    <el-table
        :data="dataList"
        show-summary
        highlight-current-row
        style="width: 100%;"
        border>
      <el-table-column
          prop="index"
          label="序号"
          align="center"
          width="60" fixed="fixed">
        <template slot-scope="{$index}">
          {{ $index + 1 }}
        </template>
      </el-table-column>
      <el-table-column
          prop="dept_name"
          label="部门"
          align="center"
          min-width="100">
      </el-table-column>
      <el-table-column
          prop="revenue_bonus"
          label="时间（季度）"
          align="center"
          min-width="110">
        <template slot-scope="scope">
          {{ scope.row.year }} 年第 {{ scope.row.date_value }} 季度
        </template>
      </el-table-column>
      <el-table-column
          prop="revenue_bonus"
          label="季度目标"
          align="center"
          min-width="110">
        <template slot-scope="scope">
          <span class="money">{{ valFormat(scope.row.target_month * 1) }}</span>
        </template>
      </el-table-column>
      <el-table-column
          prop="revenue_bonus"
          label="实际利润"
          align="center"
          min-width="110">
        <template slot-scope="scope">
          <span class="money">{{ valFormat(scope.row.real_profit * 1) }}</span>
        </template>
      </el-table-column>
      <el-table-column
          prop="revenue_bonus"
          label="目标达成率"
          align="center"
          min-width="110">
        <template slot-scope="scope">
          <span class="ratio">{{ scope.row.done_rate }}</span>
        </template>
      </el-table-column>
      <el-table-column
          prop="total_bonus"
          label="奖金"
          align="center"
          min-width="110">
        <template slot-scope="{row}">
          <span class="money">{{ valFormat(row.prize * 1) }}</span>
        </template>
      </el-table-column>
      <el-table-column
          prop='status'
          label='状态'
          align='center'
          min-width='100'>
        <template slot-scope='{row}'>
          <el-tag :type='calcStatusType(row.is_confirm_prize)'>
            {{ row.is_confirm_prize === 0 ? '未确认' : '已确认' }}
          </el-tag>
        </template>
      </el-table-column>
      <el-table-column
          width='120'
          label='操作' align='center'>
        <template slot-scope='{row}'>
          <el-button v-if=" Number(row.is_confirm_prize) === 0 " v-has-authority="'pa_kpi_share__confirm'"
                     :type='calcStatusType(row.is_confirm_prize)' @click="revokeBtn(row)">
            确认分配
          </el-button>
          <el-button v-else v-has-authority="'pa_kpi_share__revoke_confirm'"
                     :type='calcStatusType(row.is_confirm_prize)'
                     @click="revokeBtn(row)">
            撤回确认
          </el-button>
        </template>
      </el-table-column>
    </el-table>
      <el-col :span='24' style='text-align: right'>
        <Pagination :limit.sync='pageData.page_size'
                    style="padding: 0" :page.sync='pageData.current_page'
                    :total='total'
                    @pagination='getList'/>
      </el-col>
    </div>
  </div>
</template>

<script>
import BonusSearch from './component/BonusSearch.vue'
import {mapGetters} from "vuex";
import EditableCell from "@/components/EditableCell.vue";
import {numberFormat} from "@/utils/utils";

export default {
  name: "directorIndex",
  components: {
    EditableCell,
    BonusSearch
  },
  data() {
    return {
      searchCondition: {group_id: null, group_ids: [], ym: null, groups: [], status: null, type: 2},
      dialogVisible: false,
      currentId: null,
      dataList: [],
      pageData: {current_page: 1, page_size: 10},
      total: 0
    }
  },
  computed: {
    ...mapGetters(['userPermissions']),
  },
  methods: {
    calcStatusType(status) {
      let type = ''
      switch (status) {
        case 0:
          type = 'success'
          break
        case 1:
          type = 'warning'
          break
        default:
          type = 'info'
          break
      }
      return type
    },
    revokeBtn(row) {
      let status = row.is_confirm_prize === 0 ? '确认' : '撤回确认'
      this.$confirm(`此操作将审核状态至[${status}], 是否继续?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {

        this.revokeBill(row.id, row.is_confirm_prize)
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消'
        })
      })
    },
    async revokeBill(id, type) {
      //0 未确认，需确认,1,已确认,需要撤回
      let postData = {
        id: id
      }
      let data = null
      if (Number(type) === 0) {
        data = await this.$api.confirmPaKpiPrize(postData)
      } else {
        data = await this.$api.revokePaKpiPrize(postData)
      }
      if (data) {
        this.$message.success(`${type === 0 ? '确认' : '撤回确认'}成功`)
      }
      await this.getList()
    },
    doSearch(val) {
      this.searchCondition = {...val, type: 2}
      this.getList()
    },
    handleSearchCondition() {
      let condition = {}
      if (this.searchCondition.dept_id) {
        condition.dept_id = this.searchCondition.dept_id
      }
      if (this.searchCondition.quarter) {
        condition.quarter = this.searchCondition.quarter
      }
      if (this.searchCondition.type) {
        condition.type = this.searchCondition.type
      }
      if (this.searchCondition.year) {
        condition.year = this.searchCondition.year
      }
      if (this.searchCondition.months) {
        condition.months = this.searchCondition.months
      }
      if (this.searchCondition.depth) {
        condition.depth = this.searchCondition.depth
      }
      if (this.searchCondition.date_value) {
        condition.date_value = this.searchCondition.date_value
      }
      if (this.searchCondition.status != null && this.searchCondition.status !== '') {
        condition.status = this.searchCondition.status
      }
      return condition
    },

    async getList() {
      this.loading = true
      this.dataList = []
      let searchCondition = this.handleSearchCondition()
      Object.assign(searchCondition, this.pageData)
      if(searchCondition&&searchCondition.year){
        let {list, pages} = await this.$api.getShareList(searchCondition)
        this.loading = false
        this.dataList = list || []
        this.total = pages.total || 0
        this.pageData.current_page = pages.current_page || 1
        this.pageData.page_size = pages.page_size || 1
      }

    },
    valFormat(value) {
      if (value)
      return numberFormat(value, 2, '.', ',', 'round')
    },
  },
}
</script>

<style scoped>

</style>
